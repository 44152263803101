import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { defineMessage, useIntl } from "react-intl";
import { getLatestSchemaVersion } from "@services/csv/schema";

import { ExportButton as BaseExportButton } from "@components/Common/ExportButton";
import { ptsListToCSV } from "@services/csv/ptsList";
import { exportFile } from "@services/export";
import { HomeContext } from "@services/react";

export const PtsExportButton = observer(() => {
  const intl = useIntl();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const {
    setUIAlert,
    ptsPageStore: { ready, ptsList },
  } = useContext(HomeContext);

  const isDisabled = !ready || isProcessing || !ptsList.length;

  // TODO: create store method
  const handleExport = () => {
    if (isDisabled) {
      return;
    }

    try {
      setIsProcessing(true);
      const csv = ptsListToCSV(ptsList, getLatestSchemaVersion());

      exportFile("pts-list.csv", csv);
    } catch (error) {
      console.error(error);
      setUIAlert({
        message: defineMessage({
          defaultMessage: "Error exporting CSV",
          description: "Error exporting CSV alert message",
        }),
        type: "error",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <BaseExportButton
      disabled={isDisabled}
      onClick={handleExport}
      title={
        ptsList.length
          ? intl.formatMessage({
              defaultMessage: "Export CSV",
              description: "Export CSV button title",
            })
          : intl.formatMessage({
              defaultMessage: "No data to export",
              description: "Export CSV button title",
            })
      }
    >
      {intl.formatMessage({
        defaultMessage: "Export CSV",
        description: "Export CSV button label",
      })}
    </BaseExportButton>
  );
});
